import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { SiteService } from '../../core/services/data/site/site.service';

import { matchValidator } from '../../core/app-validators';

import { PROCESSING_PAGE } from '../../core/router-constants';

// FIXME: maybe someday...
// const URL_VALIDATION = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]/gm;
const URL_VALIDATION = /.+/g;

@Component({
  selector: 'app-site-page',
  templateUrl: './site-page.component.html',
  styleUrls: ['./site-page.component.scss'],
})
export class SitePageComponent {
  error = '';

  constructor(private site: SiteService, private router: Router) {}

  form: FormGroup = new FormGroup({
    url: new FormControl(
      'https://www.tag24.de/berlin/berlin-wetter/berlin-brandenburg-wetter-ostern-temperaturen-1481763',
      [Validators.required, matchValidator(URL_VALIDATION)]
    ),
  });

  submit() {
    if (this.form.valid) {
      this.site.setSite(this.form.value.url);
      this.router.navigate([`/${PROCESSING_PAGE}`]);
    }
  }
}
