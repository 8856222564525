<div class="page-info" *ngIf="pageData">
  <div *ngIf="pageData.articleTitle">
    <h2><b>Title:</b> {{ pageData.articleTitle }}</h2>
  </div>
  <div *ngIf="pageData.excerpt">
    <h2><b>Excerpt:</b> {{ pageData.excerpt }}</h2>
  </div>
  <div *ngIf="pageData.content">
    <h2><b>Content:</b> {{ pageData.content }}</h2>
  </div>
  <div *ngIf="pageData.bodySize">
    <h2><b>Body Size:</b> {{ pageData.bodySize }} bytes</h2>
  </div>
  <div *ngIf="pageData.scripts">
    <h2><b>Scripts:</b> {{ pageData.scripts }}</h2>
  </div>
  <div *ngIf="pageData.styleSheets">
    <h2><b>Stylesheets:</b> {{ pageData.styleSheets }}</h2>
  </div>
  <div *ngIf="pageData.preview">
    <h2><b>Preview:</b></h2>
    <div class="preview">
      <img [src]="pageData.preview" />
    </div>
  </div>
</div>

<h1 *ngIf="!pageData">No data</h1>
