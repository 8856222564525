import { Component, Input } from '@angular/core';
import { Page } from 'src/app/core/models';

@Component({
  selector: 'app-page-info',
  templateUrl: './page-info.component.html',
  styleUrls: ['./page-info.component.scss'],
})
export class PageInfoComponent {
  @Input() pageData: Page;
}
