<div class="wrapper">
  <div>
    <svg [attr.width]="canvasWidth" [attr.height]="canvasHeight">
      <g *ngFor="let sentense of svgSentences; let i = index">
        <g
          *ngFor="let token of sentense"
          [attr.transform]="getTokenTransform(token)"
        >
          <rect
            [attr.width]="token.width"
            height="40"
            fill="none"
            stroke="gray"
          ></rect>
          <text
            [attr.x]="getTextX(token)"
            y="15"
            aligment-baseline="middle"
            text-anchor="middle"
            font-weight="bold"
            font-size="16"
          >
            {{ token.text }}
            <tspan
              [attr.x]="getTextX(token)"
              y="35"
              fill="red"
              font-weight="normal"
              font-size="14"
            >
              {{ token.part_of_speech }}
            </tspan>
          </text>
        </g>
        <g
          *ngFor="let token of sentense"
          stroke-width="1"
          fill="none"
          stroke="green"
        >
          <path [attr.d]="getPathForToken(token, i)"></path>
          <path [attr.d]="getTokenEndArrow(token)" fill="green"></path>
        </g>
      </g>
    </svg>
  </div>
</div>

<!-- dirty hack -->
<span id="ruler"></span>
