import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authToken: string | null = null;

  constructor() {}

  login(...params: any[]): Observable<string> {
    return of('authenticated').pipe(
      delay(1000),
      tap((token) => (this.authToken = token))
    );
  }

  logout() {
    this.authToken = null;
  }
}
