<div class="container" style="padding-top: 40px;">
  <div class="logo"></div>
  <nav class="processing-nav">
    <ul>
      <li routerLinkActive="active">
        <a routerLink="./prescreening">Pre-Screening</a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="./content-results">Content</a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="./semantic-results">Semantic</a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="./syntax-results">Syntax</a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="./actions">Actions</a>
      </li>
    </ul>
  </nav>

  <div class="results">
    <router-outlet></router-outlet>
  </div>
</div>
