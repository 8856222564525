import { Injectable } from '@angular/core';

import { PageService } from '../site/page.service';

import { BatchDataProcessorService } from '../batch-data-processor.service';
import { PageDataService } from './page-data.service';
import { EntitiesEndpointService } from '../../api';

import {EntitiesData, Entities, Page} from '../../../models';
import {HttpParams} from "@angular/common/http";

@Injectable({
  providedIn: 'root',
})
export class EntitiesService extends PageDataService<EntitiesData, Entities> {
  constructor(
    source: PageService,
    batcher: BatchDataProcessorService,
    endpoint: EntitiesEndpointService
  ) {
    super(source, batcher, endpoint);
  }

  protected prepareHttpParams(data: Page) {
    return new HttpParams()
      .set('req', 'analyzeContentEntities')
      .set('text', data.content);
  }


  protected convertData({text, language, entities}: EntitiesData): Entities {
    return { text, entities };
  }
}
