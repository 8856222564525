import { Component, OnInit } from '@angular/core';

import { map } from 'rxjs/operators';

import {
  KeywordsService,
  CategoriesService,
  SentensesService,
} from 'src/app/core/services/data';
import { ProcessMonitoringService } from 'src/app/core/services/common';
import { STATUS_PROCESSING } from 'src/app/core/services/api/core/process';

@Component({
  selector: 'app-semantic-results',
  templateUrl: './semantic-results-page.component.html',
  styleUrls: ['./semantic-results-page.component.scss'],
})
export class SemanticResultsPageComponent implements OnInit {
  keywords$ = this.keywords.data$;
  keywordsLoading$ = this.monitor
    .getProcessingStatus('keywords')
    .pipe(map((value) => value === STATUS_PROCESSING));

  categories$ = this.categories.data$;
  categoriesLoading$ = this.monitor
    .getProcessingStatus('categories')
    .pipe(map((value) => value === STATUS_PROCESSING));

  sentiments$ = this.sentenses.data$;
  sentimentsLoading$ = this.monitor
    .getProcessingStatus('sentenses')
    .pipe(map((value) => value === STATUS_PROCESSING));

  constructor(
    private monitor: ProcessMonitoringService,
    private keywords: KeywordsService,
    private categories: CategoriesService,
    private sentenses: SentensesService
  ) {}

  ngOnInit(): void {}
}
