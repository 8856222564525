import { Component } from '@angular/core';

import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { AuthService } from '../../core/services/common/auth.service';
import { SITE_PAGE } from '../../core/router-constants';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent {
  error = '';

  constructor(private authService: AuthService, private router: Router) {}

  form: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(4),
    ]),
  });

  submit() {
    if (this.form.valid) {
      this.authService.login(this.form.value).subscribe((token) => {
        console.log(token);

        if (this.authService.authToken) {
          this.router.navigate([`/${SITE_PAGE}`]);
        }
      });
    }
  }
}
