import { Injectable } from '@angular/core';

import { PageService } from '../site/page.service';

import { BatchDataProcessorService } from '../batch-data-processor.service';
import { PageDataService } from './page-data.service';
import { CategoriesEndpointService } from '../../api';

import { CategoriesData, Categories } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService extends PageDataService<
  CategoriesData,
  Categories
> {
  constructor(
    source: PageService,
    batcher: BatchDataProcessorService,
    endpoint: CategoriesEndpointService
  ) {
    super(source, batcher, endpoint);
  }

  protected convertData(input: CategoriesData): Categories {
    return input.categories;
  }
}
