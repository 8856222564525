// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const endpoints: Record<string, string> = {
  page: 'https://context-api.adtech.app/',
  keywords: 'assets/mock/sample-input-keywords.json',
  categories: 'assets/mock/sample-input-categories.json',
  sentiment: 'assets/mock/sample-input-sentiment.json',
  entities: 'https://context-api.adtech.app/',
  syntax: 'https://context-api.adtech.app/',
};

export const environment = {
  production: false,
  apiUrl: '',
  endpoints,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
