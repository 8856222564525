<div style="width: 100%;">
  <table mat-table [dataSource]="sentiments">
    <ng-container matColumnDef="text">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="text">
        {{ element.text.content }}
      </td>
    </ng-container>

    <ng-container matColumnDef="score">
      <th mat-header-cell *matHeaderCellDef>Score</th>
      <td mat-cell *matCellDef="let element">
        <div class="score left" [class]="getCellClass(element)">
          {{ element.sentiment.score }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="magnitude">
      <th mat-header-cell *matHeaderCellDef>Magnitude</th>
      <td mat-cell *matCellDef="let element">
        <div class="score right blue">{{ element.sentiment.magnitude }}</div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <div class="footer">
    <div class="help-title">
      <span>Score range</span>
    </div>
    <div class="helpers">
      <div class="green">0.25 - 1.0</div>
      <div class="yellow">-0.25 - 0.25</div>
      <div class="red">-0.1 - -0.25</div>
    </div>
  </div>
</div>
