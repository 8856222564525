import { Component, Input, OnChanges } from '@angular/core';

import { Syntax, TreeNode } from '../../core/models';

@Component({
  selector: 'app-syntax',
  templateUrl: './syntax.component.html',
  styleUrls: ['./syntax.component.scss'],
})
export class SyntaxComponent implements OnChanges {
  @Input() syntax: Syntax;

  svgSentences: any[] = [];

  canvasWidth = 500;
  canvasHeight = 1000;

  constructor() {}

  ngOnChanges() {
    if (this.syntax) {
      let prevY = 0;
      for (let index = 0; index < this.syntax.sentences.length; index++) {
        const sentense = this.syntax.sentences[index];
        const tree = this.syntax.tree[index];
        const sentenseSvgTokens: any[] = [];
        this.svgSentences.push(sentenseSvgTokens);
        let prevX = 50; // padding

        prevY += measureText(sentense.content) / 2 + 20;
        for (let tIndex = 0; tIndex < this.syntax.tokens.length; tIndex++) {
          const token = this.syntax.tokens[tIndex];
          const treeData = this.findTokenInTree(tIndex, tree);

          if (treeData) {
            const width =
              Math.max(
                measureText(token.text),
                measureText(token.part_of_speech)
              ) + 20;

            const centralPoint = prevX + width / 2;

            const svgToken = {
              ...token,
              width,
              x: prevX,
              y: prevY,
              entryPoint: centralPoint - 5,
              exitPoint: centralPoint + 5,
              treeData,
            };

            sentenseSvgTokens.push(svgToken);

            prevX += width + 10; // padding
          }
        }

        this.canvasWidth = Math.max(this.canvasWidth, prevX + 50);
      }

      this.canvasHeight = Math.max(this.canvasHeight, prevY + 100);
    }
  }

  getTokenTransform(token: any) {
    return `translate(${token.x}, ${token.y})`;
  }

  getTextX(token: any) {
    return token.width / 2;
  }

  getPathForToken(token: any, index: number) {
    const treeToken = token.treeData;

    if (!treeToken || treeToken.parentId === null) {
      return '';
    }

    const totalStringLength = measureText(this.syntax.sentences[index].content);
    const parent = this.svgSentences[index].find(
      ({ treeData: { id } }: any) => id === treeToken.parentId
    );

    if (!parent) {
      return '';
    }

    const start = `${parent.exitPoint} ${parent.y - 5}`;
    const shift = token.entryPoint - parent.exitPoint;
    const prevRowY = index > 0 ? this.svgSentences[index - 1][0].y : 0;
    const rowHeight = token.y - prevRowY - 20 - 80;
    const arcHeight = (Math.abs(shift) / totalStringLength) * rowHeight;
    const arcPoints = `0 -${arcHeight} ${shift} -${arcHeight}`;
    const end = `${shift} 0`;

    return `M ${start} c ${arcPoints} ${end}`;
  }

  getTokenEndArrow(token: any) {
    if (token.treeData.parentId === null) {
      return '';
    }
    const pinacle = `${token.entryPoint} ${token.y - 4}`;
    return `M ${pinacle} l -3 -3 l 6 0 z`;
  }

  findTokenInTree(id: number, tree: TreeNode): TreeNode | null {
    if (tree.id === id) {
      return tree;
    }

    if (tree.children) {
      let result = null;
      for (const child of tree.children) {
        result = this.findTokenInTree(id, child);
        if (result) {
          break;
        }
      }
      return result;
    }

    return null;
  }
}

function measureText(text: string) {
  const ruler = document.getElementById('ruler');
  if (!ruler) {
    return 0;
  }
  ruler.innerHTML = text;
  return ruler.offsetWidth;
}
