import { Component } from '@angular/core';

import { ProcessMonitoringService } from '../../core/services/common';
import {
  BatchDataProcessorService,
  KeywordsService,
  CategoriesService,
  SentensesService,
  EntitiesService,
  SyntaxService,
} from '../../core/services/data';

@Component({
  selector: 'app-processing-page',
  templateUrl: './processing-page.component.html',
  styleUrls: ['./processing-page.component.scss'],
})
export class ProcessingPageComponent {
  statuses$ = this.monitor.processingStatuses$;

  constructor(
    // just because
    private keywords: KeywordsService,
    private categories: CategoriesService,
    private sentenses: SentensesService,
    private entities: EntitiesService,
    private syntax: SyntaxService,

    // this is necessary services
    private starter: BatchDataProcessorService,
    private monitor: ProcessMonitoringService
  ) {
    this.starter.start();
  }
}
