<p class="text">
  {{ text }}
</p>

<mat-list>
  <mat-list-item *ngFor="let entity of entities; let i = index">
    <div class="entity">
      <div class="data">
        <div class="text">
          {{ i }}. <b>{{ entity.name }}</b>
        </div>
        <div class="text"><b>Salience: </b>{{ entity.salience }}</div>
      </div>
      <div class="type" [class]="getColorForType(entity.type)">
        <span>
          {{ entity.type }}
        </span>
      </div>
    </div>
  </mat-list-item>
</mat-list>
