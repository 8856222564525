import { Injectable } from '@angular/core';

import { PageEndpointService } from '../../api';
import { SiteDataService } from './site-data.service';
import { SiteService } from './site.service';

import { PageData, Page } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class PageService extends SiteDataService<PageData, Page> {
  constructor(endpoint: PageEndpointService, site: SiteService) {
    super(site, 'advCheck', endpoint);
  }

  protected convertData(input: PageData): Page {
    return {
      articleTitle:
        input.articleTitle ?? `${input.H1s}\n${input.H2s}\n${input.H3s}`,
      excerpt: input.articleExcerpt,
      content: input.articleContent.replace(/[\r\n]+/g, '\n'),
      bodySize: input.htmlBodySize,
      scripts: input.htmlScripts,
      styleSheets: input.htmlStylesheets,
      preview: input.articleImage,
    };
  }
}
