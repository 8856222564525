<div class="progress-bar-container">
  <div class="progress-bar-item">
    <a routerLink="./prescreening" routerLinkActive="active">
      <div class="progress-icon">
        <div class="progress-icon-content">
          <mat-icon style="color: white;">poll</mat-icon>
        </div>
        <div class="item-label">Pre-Screening</div>
      </div>
    </a>
    <div class="progress-line"></div>
  </div>
  <div *ngFor="let status of statuses; let i = index" class="progress-bar-item">
    <a [routerLink]="getRoute(i)" routerLinkActive="active">
      <div class="progress-icon">
        <div class="progress-icon-content">
          <mat-icon [class]="getLoadingClass(status)">{{
            getIconForStatus(status)
          }}</mat-icon>
        </div>
        <div class="item-label">{{ getLabel(i) }}</div>
      </div>
    </a>
    <div *ngIf="i !== statuses.length - 1" class="progress-line"></div>
  </div>
</div>
