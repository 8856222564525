import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  LOGIN_PAGE,
  SITE_PAGE,
  PROCESSING_PAGE,
  PRESCREENING_PAGE,
  SYNTAX_RESULTS_PAGE,
  SEMANTIC_RESULTS_PAGE,
} from './core/router-constants';

import { LoginPageComponent } from './pages/login/login-page.component';
import { SitePageComponent } from './pages/site/site-page.component';
import { ProcessingPageComponent } from './pages/processing/processing-page.component';

import { PrescreeningPageComponent } from './pages/prescreening/prescreening-page.component';
import { SyntaxResultsPageComponent } from './pages/syntax-results/syntax-results-page.component';
import { SemanticResultsPageComponent } from './pages/semantic-results/semantic-results-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: LOGIN_PAGE },
  { path: LOGIN_PAGE, component: LoginPageComponent },
  { path: SITE_PAGE, component: SitePageComponent },
  {
    path: PROCESSING_PAGE,
    component: ProcessingPageComponent,
    children: [
      {
        path: PRESCREENING_PAGE,
        component: PrescreeningPageComponent,
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: PRESCREENING_PAGE,
      },
      {
        path: SYNTAX_RESULTS_PAGE,
        component: SyntaxResultsPageComponent,
      },
      {
        path: SEMANTIC_RESULTS_PAGE,
        component: SemanticResultsPageComponent,
      },
    ],
  },

  {
    path: '**',
    component: NotFoundPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
