import { EndpointService } from '../api';
import { DataService } from './data.service';

/**
 * Simple api-service just get data from endpoint (S) and return data R
 * S - source data interface from endpoint
 * R - result data interface after processing
 */
export class ApiDataService<S extends object, R> extends DataService<S, R> {
  constructor(protected endpoint: EndpointService<S>) {
    super();
  }
}
