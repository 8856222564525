import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { EndpointService } from './core/endpoint.service';
import { ProcessMonitoringService } from '../common';
import { PageData } from '../../models';

import { environment } from '../../../../environments/environment';

const { apiUrl, endpoints } = environment;

@Injectable({
  providedIn: 'root',
})
export class PageEndpointService extends EndpointService<PageData> {
  constructor(monitor: ProcessMonitoringService, http: HttpClient) {
    super('page', monitor, http, apiUrl, endpoints.page);
  }
}
