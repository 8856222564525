import { Observable } from 'rxjs';

/**
 * Simple data service just get data S and return R.
 * S - source data interface
 * R - result data interface after processing
 */
export class DataService<S, R> {
  constructor() {}

  public get data$() {
    return this.prepareData();
  }

  protected prepareData(): Observable<R> {
    throw new Error('Implement prepare data!');
  }

  protected convertData(_: S): R {
    throw new Error('Implement data converter!');
  }
}
