import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { UIModule } from './core/ui.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import { LoginPageComponent } from './pages/login/login-page.component';
import { SitePageComponent } from './pages/site/site-page.component';
import { ProcessingPageComponent } from './pages/processing/processing-page.component';

import { PrescreeningPageComponent } from './pages/prescreening/prescreening-page.component';
import { ProgressStatusComponent } from './components/progress-status/progress-status.component';
import { PageInfoComponent } from './components/page-info/page-info.component';

import { SemanticResultsPageComponent } from './pages/semantic-results/semantic-results-page.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { KeywordsComponent } from './components/keywords/keywords.component';
import { SentimentsComponent } from './components/sentiments/sentiments.component';

import { SyntaxResultsPageComponent } from './pages/syntax-results/syntax-results-page.component';
import { SyntaxComponent } from './components/syntax/syntax.component';
import { EntitiesComponent } from './components/entities/entities.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';

@NgModule({
  declarations: [
    AppComponent,
    // main pages
    LoginPageComponent,
    SitePageComponent,
    ProcessingPageComponent,

    // processing subpages
    PrescreeningPageComponent,
    SyntaxResultsPageComponent,
    SemanticResultsPageComponent,

    // components
    CategoriesComponent,
    KeywordsComponent,
    SentimentsComponent,
    SyntaxComponent,
    EntitiesComponent,
    ProgressStatusComponent,
    ProgressBarComponent,
    NotFoundPageComponent,
    PageInfoComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    UIModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
