import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { EndpointService } from './core/endpoint.service';
import { ProcessMonitoringService } from '../common';
import { SyntaxData } from '../../models';

import { environment } from '../../../../environments/environment';

const { apiUrl, endpoints } = environment;

@Injectable({
  providedIn: 'root',
})
export class SyntaxEndpointService extends EndpointService<SyntaxData> {
  constructor(monitor: ProcessMonitoringService, http: HttpClient) {
    super('syntax', monitor, http, apiUrl, endpoints.syntax);
  }

  loadData(params?: HttpParams): Observable<SyntaxData> {
    this.startProcess();
    return this.http
      .get<SyntaxData>( // TODO: must be post! But CORS
        `${this.apiUrl}${this.endpoint}?${params?.toString()}`,
        {} // body
      )
      .pipe(
        map((result) => {
          this.stopProcess();
          return result;
        }),
        catchError((error: HttpErrorResponse) => {
          this.stopProcess(true);
          return throwError(error);
        })
      );
  }
}
