import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { switchMap, map, shareReplay, tap } from 'rxjs/operators';

import { EndpointService } from '../../api';
import { ApiDataService } from '../api-data.service';

import { SiteService } from './site.service';

/**
 * get basic page data from page url
 * Sample: https://www.tag24.de/berlin/berlin-wetter/berlin-brandenburg-wetter-ostern-temperaturen-1481763
 */
export class SiteDataService<S extends object, R> extends ApiDataService<S, R> {
  constructor(
    protected site: SiteService,
    private request: string,
    endpoint: EndpointService<S>
  ) {
    super(endpoint);
  }

  protected prepareData(): Observable<R> {
    return this.site.data$.pipe(
      switchMap((url) =>
        this.endpoint.loadData(
          new HttpParams().set('req', this.request).set('url', url)
        )
      ),
      map((data) => this.convertData(data)),
      shareReplay()
    );
  }
}
