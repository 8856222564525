<mat-card class="mat-elevation-z0">
  <mat-card-title> <h1>Status</h1> </mat-card-title>
  <mat-card-content class="progress">
    <mat-list>
      <app-progress-status
        title="Keywords analysis"
        [loading]="
          (monitor.getProcessingStatus('keywords') | async) == 'PROCESSING'
        "
      ></app-progress-status>
      <app-progress-status
        title="Categories analysis"
        [loading]="
          (monitor.getProcessingStatus('categories') | async) == 'PROCESSING'
        "
      ></app-progress-status>
      <app-progress-status
        title="Sentiments analysis"
        [loading]="
          (monitor.getProcessingStatus('sentenses') | async) == 'PROCESSING'
        "
      ></app-progress-status>
      <app-progress-status
        title="Syntax analysis"
        [loading]="
          (monitor.getProcessingStatus('syntax') | async) == 'PROCESSING'
        "
      ></app-progress-status>
      <app-progress-status
        title="Entities analysis"
        [loading]="
          (monitor.getProcessingStatus('entities') | async) == 'PROCESSING'
        "
      ></app-progress-status>
    </mat-list>
  </mat-card-content>
</mat-card>

<mat-card class="mat-elevation-z0">
  <mat-card-title> <h1>Crawler rules</h1> </mat-card-title>
  <mat-card-content class="robots">
    <div class="robots">
      <code>
        {{ robotsTxt }}
      </code>
    </div>
  </mat-card-content>
</mat-card>

<mat-card class="mat-elevation-z0">
  <mat-card-title> <h1>Page</h1> </mat-card-title>
  <mat-card-content class="page">
    <app-page-info [pageData]="pageData | async"></app-page-info>
  </mat-card-content>
</mat-card>
