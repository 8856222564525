<div class="results">
  <div class="results-row">
    <mat-card class="mat-elevation-z0">
      <mat-card-title>
        <h1>Sentiment</h1>
      </mat-card-title>
      <mat-card-content>
        <app-sentiments
          *ngIf="!(sentimentsLoading$ | async)"
          [sentiments]="sentiments$ | async"
        ></app-sentiments>
        <mat-progress-spinner
          mode="indeterminate"
          diameter="48"
          *ngIf="sentimentsLoading$ | async"
        ></mat-progress-spinner>
      </mat-card-content>
    </mat-card>
    <mat-card class="mat-elevation-z0">
      <mat-card-title>
        <h1>Categories (IAB)</h1>
      </mat-card-title>
      <mat-card-content>
        <app-categories
          *ngIf="!(categoriesLoading$ | async)"
          [categories]="categories$ | async"
        ></app-categories>
        <mat-progress-spinner
          mode="indeterminate"
          diameter="48"
          *ngIf="categoriesLoading$ | async"
        ></mat-progress-spinner>
      </mat-card-content>
    </mat-card>
  </div>
  <mat-card class="mat-elevation-z0">
    <mat-card-title>
      <h1>Keywords</h1>
    </mat-card-title>
    <mat-card-content>
      <app-keywords
        *ngIf="!(keywordsLoading$ | async)"
        [keywords]="keywords$ | async"
      ></app-keywords>
      <mat-progress-spinner
        mode="indeterminate"
        diameter="48"
        *ngIf="keywordsLoading$ | async"
      ></mat-progress-spinner>
    </mat-card-content>
  </mat-card>
</div>
