import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { switchMap, map, shareReplay } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

import { PageService } from '../site/page.service';

import { BatchDataProcessorService } from '../batch-data-processor.service';
import { PageDataService } from './page-data.service';
import { SyntaxEndpointService } from '../../api';

import { SyntaxData, Syntax, Page } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class SyntaxService extends PageDataService<SyntaxData, Syntax> {
  constructor(
    source: PageService,
    batcher: BatchDataProcessorService,
    endpoint: SyntaxEndpointService
  ) {
    super(source, batcher, endpoint);
  }

  protected prepareHttpParams(data: Page) {
    return new HttpParams()
      .set('req', 'analyzeContentSyntax')
      .set('text', data.content);
  }

  protected convertData(input: SyntaxData): Syntax {
    const { ...syntax } = input;
    return syntax;
  }
}
