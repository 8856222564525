import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { EndpointService } from './core/endpoint.service';
import { ProcessMonitoringService } from '../common';

import { CategoriesData } from '../../models';

import { environment } from '../../../../environments/environment';

const { apiUrl, endpoints } = environment;

@Injectable({
  providedIn: 'root',
})
export class CategoriesEndpointService extends EndpointService<CategoriesData> {
  constructor(monitor: ProcessMonitoringService, http: HttpClient) {
    super('categories', monitor, http, apiUrl, endpoints.categories);
  }
}
