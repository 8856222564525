import { Injectable } from '@angular/core';

import { PageService } from '../site/page.service';

import { BatchDataProcessorService } from '../batch-data-processor.service';
import { PageDataService } from './page-data.service';
import { KeywordsEndpointService } from '../../api';

import { KeywordsData, Keywords } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class KeywordsService extends PageDataService<KeywordsData, Keywords> {
  constructor(
    source: PageService,
    batcher: BatchDataProcessorService,
    endpoint: KeywordsEndpointService
  ) {
    super(source, batcher, endpoint);
  }

  protected convertData(input: KeywordsData): Keywords {
    return input.hashtags;
  }
}
