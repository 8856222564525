import { Observable, BehaviorSubject } from 'rxjs';
import { ProcessMonitoringService } from '../../common';

export const STATUS_IDLE = 'IDLE';
export const STATUS_PROCESSING = 'PROCESSING';
export const STATUS_DONE = 'DONE';
export const STATUS_ERROR = 'ERROR';

export type ProcessStatus =
  | typeof STATUS_IDLE
  | typeof STATUS_PROCESSING
  | typeof STATUS_DONE
  | typeof STATUS_ERROR;

export interface IProcess {
  status$: Observable<ProcessStatus>;
  status: ProcessStatus;
}

export class Process implements IProcess {
  private statusSubj: BehaviorSubject<ProcessStatus> = new BehaviorSubject(
    STATUS_IDLE
  );

  constructor(
    private processName: string,
    private monitor: ProcessMonitoringService
  ) {
    this.monitor.startMonitoring(this.processName, this);
  }

  status$ = this.statusSubj.asObservable();

  get status(): ProcessStatus {
    return this.statusSubj.getValue();
  }

  protected startProcess() {
    this.statusSubj.next(STATUS_PROCESSING);
  }
  protected resetProcess() {
    this.statusSubj.next(STATUS_IDLE);
  }
  protected stopProcess(isError = false) {
    if (isError) {
      this.statusSubj.next(STATUS_ERROR);
    } else {
      this.statusSubj.next(STATUS_DONE);
    }
  }
}
