import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { switchMap, map, shareReplay, tap } from 'rxjs/operators';

import { Page } from '../../../models'; // meeeh...
import { EndpointService } from '../../api';
import { BatchDataProcessorService } from '../batch-data-processor.service';

import { ApiDataService } from '../api-data.service';
import { PageService } from '../site/page.service';

/**
 * get data from page service (💩) and proceed them to another endpoint as params
 * then return result data
 */
export class PageDataService<S extends object, R> extends ApiDataService<S, R> {
  constructor(
    protected source: PageService,
    private batcher: BatchDataProcessorService,
    endpoint: EndpointService<S>
  ) {
    super(endpoint);

    this.batcher.addDataToBatcher(this.data$);
  }

  protected prepareData(): Observable<R> {
    return this.source.data$.pipe(
      switchMap((pageData) =>
        this.endpoint.loadData(this.prepareHttpParams(pageData))
      ),
      map((data) => this.convertData(data)),
      shareReplay()
    );
  }

  protected prepareHttpParams(_: Page): HttpParams | undefined {
    return undefined;
  }
}
