import { Component, Input } from '@angular/core';

import { Keywords } from '../../core/models';

@Component({
  selector: 'app-keywords',
  templateUrl: './keywords.component.html',
  styleUrls: ['./keywords.component.scss'],
})
export class KeywordsComponent {
  @Input() keywords: Keywords = [];
}
