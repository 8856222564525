import { Component, Input } from '@angular/core';

import { Categories } from '../../core/models';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent {
  @Input() categories: Categories;
  displayedColumns = ['id', 'label', 'score', 'confident'];
}
