import { Injectable } from '@angular/core';

import { Observable, forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BatchDataProcessorService {
  private processes: Observable<any>[] = [];

  constructor() {}

  start() {
    forkJoin(this.processes).subscribe();
  }

  addDataToBatcher(process: Observable<any>) {
    this.processes.push(process);
  }
}
