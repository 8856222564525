import { Injectable } from '@angular/core';

import { PageService } from '../site/page.service';

import { BatchDataProcessorService } from '../batch-data-processor.service';
import { PageDataService } from './page-data.service';
import { SentensesEndpointService } from '../../api';

import { SentencesData, Sentences } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class SentensesService extends PageDataService<
  SentencesData,
  Sentences
> {
  constructor(
    source: PageService,
    batcher: BatchDataProcessorService,
    endpoint: SentensesEndpointService
  ) {
    super(source, batcher, endpoint);
  }

  protected convertData(input: SentencesData): Sentences {
    const { documentSentiment, sentences } = input;

    return [
      {
        text: {
          content: 'Entire document',
          beginOffset: 0,
        },
        sentiment: documentSentiment,
      },

      ...sentences,
    ];
  }
}
