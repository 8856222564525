<table mat-table [dataSource]="categories">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>ID</th>
    <td mat-cell *matCellDef="let element">{{ element.id }}</td>
  </ng-container>

  <ng-container matColumnDef="label">
    <th mat-header-cell *matHeaderCellDef>Label</th>
    <td mat-cell *matCellDef="let element">{{ element.label }}</td>
  </ng-container>

  <ng-container matColumnDef="score">
    <th mat-header-cell *matHeaderCellDef>Score</th>
    <td mat-cell *matCellDef="let element">{{ element.score }}</td>
  </ng-container>

  <ng-container matColumnDef="confident">
    <th mat-header-cell *matHeaderCellDef>Confident</th>
    <td
      mat-cell
      *matCellDef="let element"
      [class]="element.confident ? 'green' : 'red'"
    >
      {{ element.confident ? 'YES' : 'NO' }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
