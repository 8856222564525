<div class="container form-container">
  <div class="logo"></div>
  <form [formGroup]="form" (ngSubmit)="submit()">
    <h3 class="description">Please input the full page URL for the analysis</h3>
    <mat-form-field appearance="fill">
      <mat-label>Site</mat-label>
      <input
        type="text"
        matInput
        placeholder="Site url"
        formControlName="url"
      />
      <mat-error>Please enter valid url</mat-error>
    </mat-form-field>

    <p *ngIf="error" class="error">
      {{ error }}
    </p>

    <div class="form-button">
      <button type="submit" mat-raised-button color="primary">
        Start
      </button>
    </div>
  </form>
</div>
