import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { EndpointService } from './core/endpoint.service';
import { ProcessMonitoringService } from '../common';
import { KeywordsData } from '../../models';

import { environment } from '../../../../environments/environment';

const { apiUrl, endpoints } = environment;

@Injectable({
  providedIn: 'root',
})
export class KeywordsEndpointService extends EndpointService<KeywordsData> {
  constructor(monitor: ProcessMonitoringService, http: HttpClient) {
    super('keywords', monitor, http, apiUrl, endpoints.keywords);
  }
}
