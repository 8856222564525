import { Component, OnInit } from '@angular/core';

import { map } from 'rxjs/operators';

import { EntitiesService, SyntaxService } from 'src/app/core/services/data';
import { ProcessMonitoringService } from 'src/app/core/services/common';
import { STATUS_PROCESSING } from 'src/app/core/services/api/core/process';

@Component({
  selector: 'app-syntax-results',
  templateUrl: './syntax-results-page.component.html',
  styleUrls: ['./syntax-results-page.component.scss'],
})
export class SyntaxResultsPageComponent implements OnInit {
  entitesText$ = this.entities.data$.pipe(map((result) => result.text));
  entities$ = this.entities.data$.pipe(map((result) => result.entities));
  syntax$ = this.syntax.data$;

  entitiesLoading$ = this.monitor
    .getProcessingStatus('entities')
    .pipe(map((value) => value === STATUS_PROCESSING));

  syntaxLoading$ = this.monitor
    .getProcessingStatus('syntax')
    .pipe(map((value) => value === STATUS_PROCESSING));

  constructor(
    private monitor: ProcessMonitoringService,
    private entities: EntitiesService,
    private syntax: SyntaxService
  ) {}

  ngOnInit(): void {}
}
