import { Component, Input } from '@angular/core';

import { Sentences, Sentence } from '../../core/models';

@Component({
  selector: 'app-sentiments',
  templateUrl: './sentiments.component.html',
  styleUrls: ['./sentiments.component.scss'],
})
export class SentimentsComponent {
  @Input() sentiments: Sentences;
  displayedColumns = ['text', 'score', 'magnitude'];

  getCellClass(element: Sentence) {
    return element.sentiment.score > 0.25
      ? 'green'
      : element.sentiment.score > -0.1
      ? 'yellow'
      : 'red';
  }
}
