<div class="container form-container">
  <div class="logo"></div>
  <form [formGroup]="form" (ngSubmit)="submit()">
    <mat-form-field appearance="fill">
      <mat-label>User</mat-label>
      <input
        type="text"
        matInput
        placeholder="User"
        formControlName="username"
      />
      <mat-error>Invalid email</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Password</mat-label>
      <input
        type="password"
        matInput
        placeholder="Password"
        formControlName="password"
      />
      <mat-error>Invalid password</mat-error>
    </mat-form-field>

    <p *ngIf="error" class="error">
      {{ error }}
    </p>

    <div class="form-button">
      <button type="submit" mat-raised-button color="primary">
        Login
      </button>
    </div>
  </form>
</div>
