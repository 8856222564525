import { Component, OnInit, Input } from '@angular/core';

import { Entities } from '../../core/models';

@Component({
  selector: 'app-entities',
  templateUrl: './entities.component.html',
  styleUrls: ['./entities.component.scss'],
})
export class EntitiesComponent implements OnInit {
  @Input() text: string;
  @Input() entities: Entities;

  constructor() {}

  ngOnInit(): void {
  }

  getColorForType(type: string) {
    const colors: Record<string, string> = {
      PERSON: 'red',
      OTHER: 'gray',
      LOCATION: 'green',
      EVENT: 'orange',
      NUMBER: 'dark-blue',
      ORGANIZATION: 'light-blue',
    };

    return colors[type];
  }
}
