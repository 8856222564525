<div class="results">
  <mat-card class="mat-elevation-z0">
    <mat-card-title>
      <h1>Entites</h1>
    </mat-card-title>
    <mat-card-content>
      <app-entities
        [text]="entitesText$ | async"
        [entities]="entities$ | async"
        *ngIf="!(entitiesLoading$ | async)"
      ></app-entities>
      <mat-progress-spinner
        mode="indeterminate"
        diameter="48"
        *ngIf="entitiesLoading$ | async"
      >
      </mat-progress-spinner>
    </mat-card-content>
  </mat-card>
  <mat-card class="mat-elevation-z0">
    <mat-card-title>
      <h1>Syntax</h1>
    </mat-card-title>
    <mat-card-content>
      <app-syntax
        [syntax]="syntax$ | async"
        *ngIf="!(syntaxLoading$ | async)"
      ></app-syntax>
      <mat-progress-spinner
        mode="indeterminate"
        diameter="48"
        *ngIf="syntaxLoading$ | async"
      >
      </mat-progress-spinner>
    </mat-card-content>
  </mat-card>
</div>
