import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { skipWhile, share, tap } from 'rxjs/operators';

import { DataService } from '../data.service';

@Injectable({
  providedIn: 'root',
})
export class SiteService extends DataService<string, string> {
  private siteSubj: BehaviorSubject<string> = new BehaviorSubject('');

  setSite(url: string) {
    this.siteSubj.next(url);
  }

  protected prepareData(): Observable<string> {
    return this.siteSubj.pipe(
      skipWhile((value) => value === '')
      // share()
    );
  }

  // just convention
  protected convertData(url: string): string {
    return url;
  }
}
