import {
  HttpClient,
  HttpParams,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Process } from './process';
import { IEndpoint } from './endpoint';
import { ProcessMonitoringService } from '../../common';

export class EndpointService<T extends object> extends Process
  implements IEndpoint<T> {
  constructor(
    processName: string,
    monitor: ProcessMonitoringService,
    protected http: HttpClient,
    protected apiUrl: string,
    protected endpoint: string
  ) {
    super(processName, monitor);
  }

  loadData(params?: HttpParams): Observable<T> {
    this.startProcess();
    return this.http
      .get<T>(`${this.apiUrl}${this.endpoint}?${params?.toString()}`)
      .pipe(
        map((result) => {
          this.stopProcess();
          return result;
        }),
        catchError((error: HttpErrorResponse) => {
          this.stopProcess(true);
          return throwError(error);
        })
      );
  }
}
