import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';

import { EndpointService } from './core/endpoint.service';
import { ProcessMonitoringService } from '../common';
import { EntitiesData } from '../../models';

import { environment } from '../../../../environments/environment';

const { apiUrl, endpoints } = environment;

@Injectable({
  providedIn: 'root',
})
export class EntitiesEndpointService extends EndpointService<EntitiesData> {
  constructor(monitor: ProcessMonitoringService, http: HttpClient) {
    super('entities', monitor, http, apiUrl, endpoints.entities);
  }
}
