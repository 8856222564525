import { Injectable } from '@angular/core';

import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ProcessStatus,
  IProcess,
  STATUS_DONE,
  STATUS_ERROR,
  STATUS_PROCESSING,
  STATUS_IDLE,
} from '../api/core/process';

@Injectable({
  providedIn: 'root',
})
export class ProcessMonitoringService {
  private statuses$: Record<string, Observable<ProcessStatus>> = {};

  startMonitoring(name: string, process: IProcess) {
    this.statuses$[name] = process.status$;
  }

  get processingStatuses$() {
    return combineLatest(Object.values(this.statuses$));
  }

  getProcessingStatus(processName: string) {
    return this.statuses$[processName];
  }

  get processingSummary$() {
    return this.processingStatuses$.pipe(
      map((statuses) => {
        if (statuses.every((status) => status === STATUS_DONE)) {
          return STATUS_DONE;
        } else if (statuses.some((status) => status === STATUS_ERROR)) {
          return STATUS_ERROR;
        } else if (statuses.some((status) => status === STATUS_PROCESSING)) {
          return STATUS_PROCESSING;
        }

        return STATUS_IDLE;
      })
    );
  }
}
