import { Component, OnInit, Input } from '@angular/core';

type ProcessingStatus = 'LOADING' | 'DONE' | 'ERROR';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
  @Input() statuses: ProcessingStatus[];

  constructor() {}

  ngOnInit(): void {}

  getIconForStatus(status: ProcessingStatus) {
    const statusesIcons = {
      LOADING: 'autorenew',
      DONE: 'done',
      ERROR: 'error',
    };
    return statusesIcons[status];
  }

  getLoadingClass(status: ProcessingStatus) {
    return status === 'LOADING'
      ? 'loading'
      : status === 'ERROR'
      ? 'error'
      : 'done';
  }

  getRoute(index: number) {
    return index < 3 ? './semantic-results' : './syntax-results';
  }

  getLabel(index: number) {
    return ['Keywords', 'Categories', 'Sentenses', 'Syntax', 'Entities'][index];
  }
}
